export const apiConstants = {
    CREATE_USER: '/account/create-account',
    //paypal
    CONNECT_PAYPAL: (codeID: string) => `/paypal?code=${codeID}`,
    GET_CONNECTED_PAYPAL_LIST: (payload: any) => `/paypal?page=${payload?.page}&pageLength=${payload?.pageLength}`,
    DELETE_PAYPAL: (id: number) => `/paypal?id=${id}`,

    DASHBOARD_TOTAL_REVENUE_PROTECTED: '/dashboard/total-revenue-protected',
    DASHBOARD_GET_LINKED_SHOPS: '/dashboard/linked-shops',
    DASHBOARD_DELETE_LINKED_SHOPS: (payload: number) => `/shops/delete/${payload}`,
    DASHBOARD_LINKED_PAYPAL: '/dashboard/linked-paypal',
    DASHBOARD_RECENT_DISPUTES: 'dashboard/recent-disputes',
    DASHBOARD_OPEN_DISPUTES: '/dashboard/open-disputes',
    DASHBOARD_RECOVERED_DISPUTES: '/dashboard/recovered-disputes',
    DASHBOARD_CURRENT_WEEK_DISPUTES: '/dashboard/current-week-disputes',
    DASHBOARD_CURRENT_MONTH_DISPUTES: '/dashboard/current-month-disputes',
    GET_DISPUTES_LIST: (payload: any) => `/dispute?page=${payload.page}&pageLength=${payload.pageLength}`,
    FIND_FILTERED_DISPUTES: "/dispute/find-filtered-disputes",
    DASHBOARD_PROCESSING_DISPUTES: "/dashboard/processing-disputes",
    DASHBOARD_AWAITING_RESPONSE_DISPUTES: "/dashboard/awaiting-response-disputes",
    POST_DISPUTE_HANDLE_SAVE:"dispute/handle-dispute",

    //dispute-details
    GET_DISPUTE_DETAILS: (payload: any) => `/dispute/${payload}`,
    GET_DISPUTE_TRANSACTION_DETAILS: (payload: any) => `/dispute/disputed-transaction/${payload}`,
    GET_DISPUTE_EVIDENCE_DETAILS: (payload: any) => `/dispute/dispute-evidence/${payload}`,
    GET_DISPUTE_MESSAGE_DETAILS: (payload: any) => `/dispute/dispute-message/${payload}`,
    GET_DISPUTE_ADJUDICATION_DETAILS: (payload: any) => `/dispute/dispute-adjudication/${payload}`,
    GET_DISPUTE_MONEY_MOVEMENT_DETAILS: (payload: any) => `/dispute/dispute-money-movement/${payload}`,
    GET_DISPUTE_SUPPORTING_INFO_DETAILS: (payload: any) => `/dispute/dispute-supporting-info/${payload}`,
    GET_DISPUTE_OFFER_DETAILS: (payload: any) => `/dispute/dispute-offer/${payload}`,
    GET_DISPUTE_ORDER_SUMMARY: (payload: any) => `/dispute/dispute-order-summary/${payload}`,
    POST_ADD_SUPPORTING_EVIDENCE: (payload: any) => `/evidence/provide-evidence?disputeId=${payload?.disputeId}&text=${payload?.text}&comments=${payload?.comments}`,
    // dispute accordion
    GET_MANAGE_FRAUD_ANALYSIS_DETAILS: (payload: any) => `/dispute/fraud-analysis?disputeId=${payload}`,
    GET_MANAGE_DISPUTE_CUSTOMER: (payload: any) => `/dispute/customer-by-dispute?disputeId=${payload}`,
    GET_MANAGE_DISPUTE_FULFILLMENT: (payload: any) => `/dispute/fulfillment-details-by-dispute?disputeId=${payload}`,
    GET_MANAGE_DISPUTE_TRACKING_DETAILS: (payload: any) => `/dispute/track-details?disputeId=${payload}`,
    //shop
    POST_ADD_SHOPIFY_SHOP: (payload: any) => `/shops/add-shopify-shop?store=${payload?.store}&code=${payload?.code}`,
    GET_SHOPS_NOTIFICATIONS: "/shops/notifications",
    POST_SHOPS_SAVE_NOTIFICATIONS: "/shops/save-notifications",
    POST_SHOPS_ADD_ONBOARDED: "/shops/add-onboarded",
    POST_SHOPS_ADD_RISK: "/shops/add-risk",
    GET_SHOPS_DATA: "/shops",

    //shopify-controller
    GET_SHOPIFY_HMAC: (payload: string) => `/shopify/hmac?shop=${payload}`,
    POST_SHOPIFY_CREATE_CHARGE: "/shopify/create-charge",
    POST_SHOPIFY_CREATE_SUBSCRIPTION: "/shopify/create-subscription",
    POST_SHOPIFY_ACTIVATE_CHARGE: (payload: any) => `/shopify/activate-charge?chargeId=${payload}`,

    //notifcations
    GET_NOTIFICATIONS_LIST: "/notifications/list",

    //BrianTree
    POST_BRAINTREE_SETTINGS: "/braintree/settings",
    GET_BRAINTREE_LIST: (payload: any) => `/braintree/list?page=${payload?.page}&pageLength=${payload?.pageLength}`,
    //Klarna
    POST_KLARNA_SETTINGS: "/klarna/settings",
    GET_KLARNA_LIST: (payload: any) => ``,
    //List-PSP
    GET_LIST_PSP_LIST: "/listpsp/psp-list",
    //stripe-integartion
    POST_STRIPE_LOGIN: (payload: any) => `/stripe/login?userId=${payload.userId}&accountId=${payload.accountId}&signature=${payload.signature}`,
    POST_STRIPE_ONBOARD_LOGIN: "/stripe/onboard",
    POST_STRIPE_UPDATE_BUSINESS_PROFILE: `/stripe/update-business-profile`,

    // api signup
    POST_AUTH_API_SIGNUP:`/auth/login`
}   