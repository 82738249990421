import { svgIcons } from 'elements';
import { useState } from 'react';
import styles from './style.module.scss';
import ChargeFlow from "../../../assets/chargeFlow.webp"

const cardsData = [
  {
    id: '1',
    cardImg: 'chargeflow',
    title: 'Chargeflow',
    desc: 'lorem ipsum dolor sit amet connector innit',
    connect: false,
  },
  {
    id: '2',
    cardImg: 'Shopify',
    title: 'Shopify',
    desc: 'lorem ipsum dolor sit amet connector innit',
    connect: false,
  },
  {
    id: '3',
    cardImg: 'Wix',
    title: 'Wix',
    desc: 'lorem ipsum dolor sit amet connector innit',
    connect: false,
  },
  {
    id: '4',
    cardImg: 'Squarespace',
    title: 'Squarespace',
    desc: 'lorem ipsum dolor sit amet connector innit',
    connect: false,
  },
  {
    id: '5',
    cardImg: 'BrainTree',
    title: 'Braintree',
    desc: 'lorem ipsum dolor sit amet connector innit',
    connect: false,
  },
  {
    id: '6',
    cardImg: 'Salesforce',
    title: 'Salesforce',
    desc: 'lorem ipsum dolor sit amet connector innit',
    connect: false,
  },
];

const Platforms = () => {
  const [cards, setCards] = useState(cardsData);

  const handleConnect = (id: string) => {
    setCards(prevCard =>
      prevCard.map(card => (card.id === id ? { ...card, connect: true } : card))
    );
  };

  return (
    <div className={styles.cardsContainer}>
      {cards.map(card => (
        <div key={card.id} className={styles.cardData}>
          <div className={styles.cardImg}>
            <img
              src={
                card.cardImg === 'chargeflow'
                  ? ChargeFlow
                  : card.cardImg === 'Shopify'
                  ? svgIcons.ShopifyIcon
                  : card.cardImg === 'Wix'
                  ? svgIcons.WixIcon
                  : card.cardImg === 'Squarespace'
                  ? svgIcons.Squarespace
                  : card.cardImg === 'BrainTree'
                  ? svgIcons.BrainTree
                  : svgIcons.SalesForceIcon
              }
              alt='icon'
            />
          </div>
          <div className={styles.cardText}>
            <p className={styles.cardTitle}>{card.title}</p>
            <p>{card.desc}</p>
            <button
              className={styles.connectBtn}
              style={{
                border: `${
                  card.connect ? '1px solid #20d120' : '1px solid #ddd '
                }`,
                color: card.connect ? '#20d120' : 'gray',
                background: `${card.connect} ? "#edf0ed" : ""`,
              }}
              onClick={() => handleConnect(card.id)}
            >
              {card.connect ? 'Connected' : 'Connect'}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Platforms;
