import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getItem, removeItem } from "utils/localStorage";

import {
  getOpenDisputesRequest,
  getRecoveredDisputesRequest,
  getShopsDataRequest,
  postShopifyActivateChargeRequest,
  getRecentDisputesRequest,
  postAuthApiSignupRequest
} from "redux/actions";
import { useAuth0 } from "@auth0/auth0-react";

interface userDataProp {
  id: number;
  externalId: string;
  appId: string;
  domain: string;
  name: string;
  email: string;
  phone: string;
  shopType: string;
  bigCommerceStoreHash: string;
  bigCommerceStoreInternalHash: string;
  accessToken: string;
  refreshToken: string;
  scopes: string;
  onboarded: boolean;
  riskAccepted: boolean;
  chargeActive: boolean;
}

const useDashboard = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const chargeId = searchParams.get("charge_id");
  const [subscribed, setSubscribed] = useState(false);
  const [userData, setUserData] = useState<userDataProp>();
  const [disputeRisk, setDisputeRisk] = useState(false);
  const AddedAccount = getItem("AddAccount");
  const shopsData = useSelector((state: any) => state?.shop?.shopsData);
  const { isSuccess, data, isLoading: isSignupLoading } = useSelector((state: any) => state?.stripe?.authSignup);

  const authData = useSelector((state: any) => state.authStore);
  const { isLoading } = authData.userDetails;
  const { openDisputesData, recoveredDisputesData, recentDisputesData } =
    useSelector((state: any) => state.dashboard);
  const userDataObj: string | any = getItem("addShopifyShop");
  const addShopifyShopData = JSON.parse(userDataObj);
  console.log({ data })
  const shopifyActivateCharge = useSelector(
    (state: any) => state?.shopifyController?.shopifyActivateCharge
  );
  const {
    data: stripeData,
    isLoading: isStripeLoading,
    isError: isStripeError,
  } = useSelector((state: any) => state?.stripe?.stripeLogin);
  const shopsAddRisk = useSelector((state: any) => state?.shop?.shopsAddRisk);
  const {
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    user
  } = useAuth0();
  const tokenHandler = async () => {
    try {
      const accessToken = await getAccessTokenSilently()
      const idTokenClaims = await getIdTokenClaims()
      const idToken = idTokenClaims?.__raw
      const payload = {
        access_token: accessToken,
        id_token: idToken
      }
      dispatch(postAuthApiSignupRequest(payload))
    } catch (error: any) {
      console.log(error);
    }
  };
  console.log({ isAuthenticated })
  useEffect(() => {
    if (isAuthenticated) {
      tokenHandler();
      toast.success("User login successfull", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [isAuthenticated]);
  useEffect(() => {
    if (shopsData?.isSuccess) {
      setUserData(shopsData?.data);
    }
  }, [shopsData]);
  useEffect(() => {
    if (isSuccess) {
      setUserData(data);
    }
  }, [data]);
  //Auth0 Stuff
  console.log({ stripeData, shopsData })


  //to-check-activate-charge
  useEffect(() => {
    if (
      shopifyActivateCharge?.isSuccess &&
      shopifyActivateCharge?.data?.chargeActive
    ) {
      dispatch(getShopsDataRequest());
    } else if (shopifyActivateCharge?.isError) {
      toast.error(
        "Oops! Something went wrong with activation. Please try again.",
        {
          theme: "colored",
        }
      );
    }
  }, [shopifyActivateCharge, dispatch]);

  useEffect(() => {
    if (chargeId) {
      dispatch(postShopifyActivateChargeRequest(chargeId));
    }
  }, [chargeId, dispatch]);

  const onActivateBannerClose = () => {
    setSubscribed(!subscribed);
    setDisputeRisk(true);
  };
  const onDisputesRiskClose = () => {
    setDisputeRisk(false);
  };
  const recentDisputesHeading = {
    title: "Recent Disputes",
    description:
      !recentDisputesData?.isLoading &&
      recentDisputesData?.isSuccess &&
      recentDisputesData?.data &&
      recentDisputesData?.data?.length > 0 &&
      `You have ${recentDisputesData?.data?.length > 10
        ? 10
        : recentDisputesData?.data?.length
      } active disputes that needs your attention`,
    viewAll:
      !recentDisputesData?.isLoading &&
      recentDisputesData?.isSuccess &&
      recentDisputesData?.data &&
      recentDisputesData?.data?.length > 0 &&
      true,
    link: "dispute",
  };
  const fromStripe = getItem("fromStripe")
  useEffect(() => {
    if (
      stripeData &&
      Object.prototype.hasOwnProperty.call(stripeData, "additionalMandatoryShopDataRequired") &&
      fromStripe === "stripe"
    ) {
      dispatch(getOpenDisputesRequest());
      dispatch(getRecoveredDisputesRequest());
      dispatch(getRecentDisputesRequest());
      dispatch(getShopsDataRequest());
    } else if (fromStripe !== "stripe" && isSuccess) {
      dispatch(getOpenDisputesRequest());
      dispatch(getRecoveredDisputesRequest());
      dispatch(getRecentDisputesRequest());
    }
  }, [dispatch, isSuccess, stripeData]);

  useEffect(() => {
    if (AddedAccount === "true") {
      setTimeout(() => {
        toast.success("Account have been added successfully", {
          theme: "colored",
        });
        removeItem("AddAccount");
      }, 5000);
    } else if (AddedAccount === "false") {
      setTimeout(() => {
        toast.error("Oops! Something went wrong. Please try again.", {
          theme: "colored",
        });
        removeItem("AddAccount");
      }, 5000);
    }
  }, [AddedAccount]);

  useEffect(() => {
    if (shopsAddRisk?.isSuccess && shopsAddRisk?.data?.riskAccepted) {
      dispatch(getShopsDataRequest());
    } else if (shopsAddRisk?.isError) {
      toast.error("Oops! Something went wrong. Please try again.", {
        theme: "colored",
      });
    }
  }, [shopsAddRisk]);

  return {
    userData,
    disputeRisk,
    stripeData,
    isStripeLoading,
    isStripeError,
    onActivateBannerClose,
    onDisputesRiskClose,
    recentDisputesHeading,
    isLoading,
    openDisputesData,
    recoveredDisputesData,
    addShopifyShopData,
    subscribed,
    setSubscribed,
  };
};

export default useDashboard;
