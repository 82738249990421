import { createAction } from 'redux-actions';
import * as constants from './constants'

export const postCreateUserRequest = createAction(constants.POST_CREATE_USER_REQUEST);
export const postCreateUserSuccess = createAction(constants.POST_CREATE_USER_SUCCESS);
export const postCreateUserError = createAction(constants.POST_CREATE_USER_ERROR);

//paypal
export const postConnectPayPalRequest = createAction(constants.POST_CONNECT_PAYPAL_REQUEST);
export const postConnectPayPalSuccess = createAction(constants.POST_CONNECT_PAYPAL_SUCCESS);
export const postConnectPayPalError = createAction(constants.POST_CONNECT_PAYPAL_ERROR);

export const getConnectedPayPalListRequest = createAction(constants.GET_CONNECTED_PAYPAL_LIST_REQUEST);
export const getConnectedPayPalListSuccess = createAction(constants.GET_CONNECTED_PAYPAL_LIST_SUCCESS);
export const getConnectedPayPalListError = createAction(constants.GET_CONNECTED_PAYPAL_LIST_ERROR);

export const deletePayPalRequest = createAction(constants.DELETE_PAYPAL_REQUEST);
export const deletePayPalSuccess = createAction(constants.DELETE_PAYPAL_SUCCESS);
export const deletePayPalError = createAction(constants.DELETE_PAYPAL_ERROR);


export const getTotalRevenueProtectedRequest = createAction(constants.GET_TOTAL_REVENUE_PROTECTED_REQUEST);
export const getTotalRevenueProtectedSuccess = createAction(constants.GET_TOTAL_REVENUE_PROTECTED_SUCCESS);
export const getTotalRevenueProtectedError = createAction(constants.GET_TOTAL_REVENUE_PROTECTED_ERROR);

export const getLinkedShopsRequest = createAction(constants.GET_LINKED_SHOPS_REQUEST);
export const getLinkedShopsSuccess = createAction(constants.GET_LINKED_SHOPS_SUCCESS);
export const getLinkedShopsError = createAction(constants.GET_LINKED_SHOPS_ERROR);

export const deleteLinkedShopsRequest = createAction(constants.DELETE_LINKED_SHOPS_REQUEST);
export const deleteLinkedShopsSuccess = createAction(constants.DELETE_LINKED_SHOPS_SUCCESS);
export const deleteLinkedShopsError = createAction(constants.DELETE_LINKED_SHOPS_ERROR);

export const getLinkedPayPalRequest = createAction(constants.GET_LINKED_PAYPAL_REQUEST);
export const getLinkedPayPalSuccess = createAction(constants.GET_LINKED_PAYPAL_SUCCESS);
export const getLinkedPayPalError = createAction(constants.GET_LINKED_PAYPAL_ERROR);

export const getRecentDisputesRequest = createAction(constants.GET_RECENT_DISPUTES_REQUEST);
export const getRecentDisputesSuccess = createAction(constants.GET_RECENT_DISPUTES_SUCCESS);
export const getRecentDisputesError = createAction(constants.GET_RECENT_DISPUTES_ERROR);

export const getOpenDisputesRequest = createAction(constants.GET_OPEN_DISPUTES_REQUEST);
export const getOpenDisputesSuccess = createAction(constants.GET_OPEN_DISPUTES_SUCCESS);
export const getOpenDisputesError = createAction(constants.GET_OPEN_DISPUTES_ERROR);

export const getRecoveredDisputesRequest = createAction(constants.GET_RECOVERED_DISPUTES_REQUEST);
export const getRecoveredDisputesSuccess = createAction(constants.GET_RECOVERED_DISPUTES_SUCCESS);
export const getRecoveredDisputesError = createAction(constants.GET_RECOVERED_DISPUTES_ERROR);

export const getCurrentWeekDisputesRequest = createAction(constants.GET_CURRENT_WEEK_DISPUTES_REQUEST);
export const getCurrentWeekDisputesSuccess = createAction(constants.GET_CURRENT_WEEK_DISPUTES_SUCCESS);
export const getCurrentWeekDisputesError = createAction(constants.GET_CURRENT_WEEK_DISPUTES_ERROR);

export const getCurrentMonthDisputesRequest = createAction(constants.GET_CURRENT_MONTH_DISPUTES_REQUEST);
export const getCurrentMonthDisputesSuccess = createAction(constants.GET_CURRENT_MONTH_DISPUTES_SUCCESS);
export const getCurrentMonthDisputesError = createAction(constants.GET_CURRENT_MONTH_DISPUTES_ERROR);

export const getDisputesListRequest = createAction(constants.GET_DISPUTES_LIST_REQUEST);
export const getDisputesListSuccess = createAction(constants.GET_DISPUTES_LIST_SUCCESS);
export const getDisputesListError = createAction(constants.GET_DISPUTES_LIST_ERROR);

export const postFilteredDisputesRequest = createAction(constants.POST_FILTERED_DISPUTES_REQUEST);
export const postFilteredDisputesSuccess = createAction(constants.POST_FILTERED_DISPUTES_SUCCESS);
export const postFilteredDisputesError = createAction(constants.POST_FILTERED_DISPUTES_ERROR);

export const getProcessingDisputesRequest = createAction(constants.GET_PROCESSING_DISPUTES_REQUEST);
export const getProcessingDisputesSuccess = createAction(constants.GET_PROCESSING_DISPUTES_SUCCESS);
export const getProcessingDisputesError = createAction(constants.GET_PROCESSING_DISPUTES_ERROR);

export const getAwaitingResponseDisputesRequest = createAction(constants.GET_AWAITING_RESPONSE_DISPUTES_REQUEST);
export const getAwaitingResponseDisputesSuccess = createAction(constants.GET_AWAITING_RESPONSE_DISPUTES_SUCCESS);
export const getAwaitingResponseDisputesError = createAction(constants.GET_AWAITING_RESPONSE_DISPUTES_ERROR);

//dispute-details
export const getDisputeDetailsRequest = createAction(constants.GET_DISPUTE_DETAILS_REQUEST);
export const getDisputeDetailsSuccess = createAction(constants.GET_DISPUTE_DETAILS_SUCCESS);
export const getDisputeDetailsError = createAction(constants.GET_DISPUTE_DETAILS_ERROR);

export const getDisputeTransactionDetailsRequest = createAction(constants.GET_DISPUTE_TRANSACTION_DETAILS_REQUEST);
export const getDisputeTransactionDetailsSuccess = createAction(constants.GET_DISPUTE_TRANSACTION_DETAILS_SUCCESS);
export const getDisputeTransactionDetailsError = createAction(constants.GET_DISPUTE_TRANSACTION_DETAILS_ERROR);

export const getDisputeEvidenceDetailsRequest = createAction(constants.GET_DISPUTE_EVIDENCE_DETAILS_REQUEST);
export const getDisputeEvidenceDetailsSuccess = createAction(constants.GET_DISPUTE_EVIDENCE_DETAILS_SUCCESS);
export const getDisputeEvidenceDetailsError = createAction(constants.GET_DISPUTE_EVIDENCE_DETAILS_ERROR);

export const getDisputeMessageDetailsRequest = createAction(constants.GET_DISPUTE_MESSAGE_DETAILS_REQUEST);
export const getDisputeMessageDetailsSuccess = createAction(constants.GET_DISPUTE_MESSAGE_DETAILS_SUCCESS);
export const getDisputeMessageDetailsError = createAction(constants.GET_DISPUTE_MESSAGE_DETAILS_ERROR);

export const getDisputeAdjudicationDetailsRequest = createAction(constants.GET_DISPUTE_ADJUDICATION_DETAILS_REQUEST);
export const getDisputeAdjudicationDetailsSuccess = createAction(constants.GET_DISPUTE_ADJUDICATION_DETAILS_SUCCESS);
export const getDisputeAdjudicationDetailsError = createAction(constants.GET_DISPUTE_ADJUDICATION_DETAILS_ERROR);

export const getDisputeMoneyMovementDetailsRequest = createAction(constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_REQUEST);
export const getDisputeMoneyMovementDetailsSuccess = createAction(constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_SUCCESS);
export const getDisputeMoneyMovementDetailsError = createAction(constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_ERROR);

export const getDisputeSupportingInfoDetailsRequest = createAction(constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_REQUEST);
export const getDisputeSupportingInfoDetailsSuccess = createAction(constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_SUCCESS);
export const getDisputeSupportingInfoDetailsError = createAction(constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_ERROR);

export const getDisputeOfferDetailsRequest = createAction(constants.GET_DISPUTE_OFFER_DETAILS_REQUEST);
export const getDisputeOfferDetailsSuccess = createAction(constants.GET_DISPUTE_OFFER_DETAILS_SUCCESS);
export const getDisputeOfferDetailsError = createAction(constants.GET_DISPUTE_OFFER_DETAILS_ERROR);

export const getDisputeOrderSummaryRequest = createAction(constants.GET_DISPUTE_ORDER_SUMMARY_REQUEST);
export const getDisputeOrderSummarySuccess = createAction(constants.GET_DISPUTE_ORDER_SUMMARY_SUCCESS);
export const getDisputeOrderSummaryError = createAction(constants.GET_DISPUTE_ORDER_SUMMARY_ERROR);

export const getDisputeFraudAnalysisRequest = createAction(constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_REQUEST);
export const getDisputeFraudAnalysisSuccess = createAction(constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_SUCCESS);
export const getDisputeFraudAnalysisError = createAction(constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_ERROR);

export const getDisputeCustomerDetailsRequest = createAction(constants.GET_DISPUTE_CUSTOMER_DETAILS_REQUEST);
export const getDisputeCustomerDetailsSuccess = createAction(constants.GET_DISPUTE_CUSTOMER_DETAILS_SUCCESS);
export const getDisputeCustomerDetailsError = createAction(constants.GET_DISPUTE_CUSTOMER_DETAILS_ERROR);

export const getDisputeOrderFulfillmentDetailsRequest = createAction(constants.GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_REQUEST);
export const getDisputeOrderFulfillmentDetailsSuccess = createAction(constants.GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_SUCCESS);
export const getDisputeOrderFulfillmentDetailsError = createAction(constants.GET_DISPUTE_ORDER_FULFILLMENT_DETAILS_ERROR);

export const getDisputeTrackingDetailsRequest = createAction(constants.GET_DISPUTE_TRACKING_DETAILS_REQUEST);
export const getDisputeTrackingDetailsSuccess = createAction(constants.GET_DISPUTE_TRACKING_DETAILS_SUCCESS);
export const getDisputeTrackingDetailsError = createAction(constants.GET_DISPUTE_TRACKING_DETAILS_ERROR);

export const postAddSupprotingEvidenceRequest = createAction(constants.POST_ADD_SUPPORTING_EVIDENCE_REQUEST);
export const postAddSupprotingEvidenceSuccess = createAction(constants.POST_ADD_SUPPORTING_EVIDENCE_SUCCESS);
export const postAddSupprotingEvidenceError = createAction(constants.POST_ADD_SUPPORTING_EVIDENCE_ERROR);

//shops
export const postAddShopifyShopRequest = createAction(constants.POST_ADD_SHOPIFY_SHOP_REQUEST);
export const postAddShopifyShopSuccess = createAction(constants.POST_ADD_SHOPIFY_SHOP_SUCCESS);
export const postAddShopifyShopError = createAction(constants.POST_ADD_SHOPIFY_SHOP_ERROR);

export const getShopsNotificationsRequest = createAction(constants.GET_SHOPS_NOTIFICATIONS_REQUEST);
export const getShopsNotificationsSuccess = createAction(constants.GET_SHOPS_NOTIFICATIONS_SUCCESS);
export const getShopsNotificationsError = createAction(constants.GET_SHOPS_NOTIFICATIONS_ERROR);

export const postShopsSaveNotificationsRequest = createAction(constants.POST_SHOPS_SAVE_NOTIFICATIONS_REQUEST);
export const postShopsSaveNotificationsSuccess = createAction(constants.POST_SHOPS_SAVE_NOTIFICATIONS_SUCCESS);
export const postShopsSaveNotificationsError = createAction(constants.POST_SHOPS_SAVE_NOTIFICATIONS_ERROR);

export const postShopsAddOnboardedRequest = createAction(constants.POST_SHOPS_ADD_ONBOARDED_REQUEST);
export const postShopsAddOnboardedSuccess = createAction(constants.POST_SHOPS_ADD_ONBOARDED_SUCCESS);
export const postShopsAddOnboardedError = createAction(constants.POST_SHOPS_ADD_ONBOARDED_ERROR);

export const postShopsAddRiskRequest = createAction(constants.POST_SHOPS_ADD_RISK_REQUEST);
export const postShopsAddRiskSuccess = createAction(constants.POST_SHOPS_ADD_RISK_SUCCESS);
export const postShopsAddRiskError = createAction(constants.POST_SHOPS_ADD_RISK_ERROR);

export const getShopsDataRequest = createAction(constants.GET_SHOPS_DATA_REQUEST);
export const getShopsDataSuccess = createAction(constants.GET_SHOPS_DATA_SUCCESS);
export const getShopsDataError = createAction(constants.GET_SHOPS_DATA_ERROR);

//shopify-controller
export const getShopifyHmacRequest = createAction(constants.GET_SHOPIFY_HMAC_REQUEST);
export const getShopifyHmacSuccess = createAction(constants.GET_SHOPIFY_HMAC_SUCCESS);
export const getShopifyHmacError = createAction(constants.GET_SHOPIFY_HMAC_ERROR);

export const postShopifyCreateChargeRequest = createAction(constants.POST_SHOPIFY_CREATE_CHARGE_REQUEST);
export const postShopifyCreateChargeSuccess = createAction(constants.POST_SHOPIFY_CREATE_CHARGE_SUCCESS);
export const postShopifyCreateChargeError = createAction(constants.POST_SHOPIFY_CREATE_CHARGE_ERROR);

export const postShopifyCreateSubscriptionRequest = createAction(constants.POST_SHOPIFY_CREATE_SUBSCRIPTION_REQUEST);
export const postShopifyCreateSubscriptionSuccess = createAction(constants.POST_SHOPIFY_CREATE_SUBSCRIPTION_SUCCESS);
export const postShopifyCreateSubscriptionError = createAction(constants.POST_SHOPIFY_CREATE_SUBSCRIPTION_ERROR);

export const postShopifyActivateChargeRequest = createAction(constants.POST_SHOPIFY_ACTIVATE_CHARGE_REQUEST);
export const postShopifyActivateChargeSuccess = createAction(constants.POST_SHOPIFY_ACTIVATE_CHARGE_SUCCESS);
export const postShopifyActivateChargeError = createAction(constants.POST_SHOPIFY_ACTIVATE_CHARGE_ERROR);

//notification
export const getNotificationsListRequest = createAction(constants.GET_NOTIFICATIONS_LIST_REQUEST);
export const getNotificationsListSuccess = createAction(constants.GET_NOTIFICATIONS_LIST_SUCCESS);
export const getNotificationsListError = createAction(constants.GET_NOTIFICATIONS_LIST_ERROR);

//BrianTree
export const postBrainTreeSettingsRequest = createAction(constants.POST_BRAINTREE_SETTINGS_REQUEST);
export const postBrainTreeSettingsSuccess = createAction(constants.POST_BRAINTREE_SETTINGS_SUCCESS);
export const postBrainTreeSettingsError = createAction(constants.POST_BRAINTREE_SETTINGS_ERROR);

export const getBrainTreeListRequest = createAction(constants.GET_BRAINTREE_LIST_REQUEST);
export const getBrainTreeListSuccess = createAction(constants.GET_BRAINTREE_LIST_SUCCESS);
export const getBrainTreeListError = createAction(constants.GET_BRAINTREE_LIST_ERROR);

//Klarna
export const postKlarnaSettingsRequest = createAction(constants.POST_KLARNA_SETTINGS_REQUEST);
export const postKlarnaSettingsSuccess = createAction(constants.POST_KLARNA_SETTINGS_SUCCESS);
export const postKlarnaSettingsError = createAction(constants.POST_KLARNA_SETTINGS_ERROR);

export const getKlarnaListRequest = createAction(constants.GET_KLARNA_LIST_REQUEST);
export const getKlarnaListSuccess = createAction(constants.GET_KLARNA_LIST_SUCCESS);
export const getKlarnaListError = createAction(constants.GET_KLARNA_LIST_ERROR);

//List-PSP
export const getListPspListRequest = createAction(constants.GET_LIST_PSP_LIST_REQUEST);
export const getListPspListSuccess = createAction(constants.GET_LIST_PSP_LIST_SUCCESS);
export const getListPspListError = createAction(constants.GET_LIST_PSP_LIST_ERROR);

//stripe-integartion
export const postStripeLoginRequest = createAction(constants.POST_STRIPE_LOGIN_REQUEST);
export const postStripeLoginSuccess = createAction(constants.POST_STRIPE_LOGIN_SUCCESS);
export const postStripeLoginError = createAction(constants.POST_STRIPE_LOGIN_ERROR);

//stripe-integartion
export const postStripeOnboardRequest = createAction(constants.POST_STRIPE_ONBOARD_LOGIN_REQUEST);
export const postStripeOnboardSuccess = createAction(constants.POST_STRIPE_ONBOARD_LOGIN_SUCCESS);
export const postStripeOnboardError = createAction(constants.POST_STRIPE_ONBOARD_LOGIN_ERROR);

// signup api integration
export const postAuthApiSignupRequest = createAction(constants.POST_AUTH_API_SIGNUP_REQUEST)
export const postAuthApiSignupSuccess = createAction(constants.POST_AUTH_API_SIGNUP_SUCCESS)
export const postAuthApiSignupError   = createAction(constants.POST_AUTH_API_SIGNUP_ERROR)

//stripe-update-business-profile
export const postStripeUpdateBusinessProfileRequest = createAction(constants.POST_STRIPE_UPDATE_BUSINESS_PROFILE_REQUEST);
export const postStripeUpdateBusinessProfileSuccess = createAction(constants.POST_STRIPE_UPDATE_BUSINESS_PROFILE_SUCCESS);
export const postStripeUpdateBusinessProfileError = createAction(constants.POST_STRIPE_UPDATE_BUSINESS_PROFILE_ERROR);

// handle dispute response
export const postHandledByDisputeRequest = createAction(constants.POST_HANDLED_BY_DISPUTE_REQUEST);
export const postHandledByDisputeSuccess = createAction(constants.POST_HANDLED_BY_DISPUTE_SUCCESS);
export const postHandledByDisputeError   = createAction(constants.POST_HANDLED_BY_DISPUTE_ERROR)
