import { Routes, Route, Outlet } from 'react-router-dom';
import { Sidebar } from './components';
import ProtectedRoute from './context/auth/protect-route';
import { AuthenticateStore } from './stores/AuthenticateStore';
import {
  LoginPage,
  RegisterPage,
  Paypal,
  TrialPage,
  PaddleBuyPage,
  Home,
  NotificationPage,
  Disputes,
  NotFound,
  DisputeDetails,
  Settings,
  Integrations,
  NewLogin
} from './views';
import StripeInstall from 'views/stripeInstall';
import NewSignup from 'components/newSignup/Signup';

const PageLayout = () => {
  return (
    <div className="pageWrapper">
      <Sidebar />
      <div className="pageBody">
        <Outlet />
      </div>
    </div>
  );
};

export default function App() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/newLogin" element={<NewLogin />} />
      <Route path="/newSignup" element={<NewSignup />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/paypal" element={<Paypal />} />
      <Route path="/trial" element={<TrialPage />} />
      <Route path="/option" element={<PaddleBuyPage />} />
      <Route path="/" element={<LoginPage />} />
      <Route path="/stripeInstall" element={<StripeInstall />} />
      <Route element={<PageLayout />}>
        <Route path="/home" element={<ProtectedRoute component={Home} />} />
        <Route path="/notification" element={<ProtectedRoute component={NotificationPage} />} />
        <Route path="/dispute" element={<ProtectedRoute component={Disputes} />} />
        <Route path="/dispute/:disputeId" element={<ProtectedRoute component={DisputeDetails} />} />
        <Route path="/settings" element={<ProtectedRoute component={Settings} />} />
        <Route path="/integrations" element={<ProtectedRoute component={Integrations} />} />
        <Route path="/square/authenticate" element={<AuthenticateStore />} />
        <Route path="/ecwid/authenticate" element={<AuthenticateStore />} />
        <Route path="/bigcommerce/authenticate" element={<AuthenticateStore />} />
        <Route path="/wix/authenticate" element={<AuthenticateStore />} />
        <Route path="/shopify/authenticate" element={<AuthenticateStore />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
