import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";

import { configs } from '../../../configs/index';


export class PostApiAuthSignup {
  /**
   * Function to invoke to stripe
   */

  async invoke(payload: any, config: any) {
    //save access token from res header and idToken as payload
    const res = await ApiService.getInstance().post(apiConstants.POST_AUTH_API_SIGNUP, payload, { baseURL: configs.BASE_URL as string }, config)

    return res;
  }

}