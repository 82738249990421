import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { generateShopifyAuthorizeUrl } from "utils/GenerateStoreUrls";

import styles from "./style.module.scss";
import { Button, InputAdornment, OutlinedInput } from "@mui/material";
import { getItem, setItem } from "utils/localStorage";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";


const Login: FC = () => {
  const [userDomain, setUserDomain] = useState("");
  const [searchParams] = useSearchParams();
  const shop = searchParams.get("shop");
  const accessToken = getItem("accessToken");
  const {
    data: stripeData,
    isLoading: isStripeLoading,
    isError,
  } = useSelector((state: any) => state?.stripe?.stripeLogin);
  const fromStripe = getItem("fromStripe");
  const navigate = useNavigate();
  useEffect(() => {
    if (shop) {
      // const payload = {
      //   shop
      // }
      // dispatch(getShopifyHmacRequest(payload))
      //api that will provide shopify-redirect-url

      const shopifyRedirectUrl = generateShopifyAuthorizeUrl(shop);
      shopifyRedirectUrl && window.location.replace(shopifyRedirectUrl);
    }
  }, [shop]);

  useEffect(() => {
    if(stripeData?.accessToken){
      navigate("/home");
    }
  }, [stripeData?.accessToken])

  const handleInputChange = (event: any) => {
    setUserDomain(event.target.value);
  };

  const constructAuthUrl = (host: string) => {
    const hostDomain = `${host}.myshopify.com`;
    const clientId = process.env.REACT_APP_SHOPIFY_CLIENT_ID;
    const scope = process.env.REACT_APP_SHOPIFY_SCOPES;
    const redirectUri = encodeURIComponent(
      process.env.REACT_APP_SHOPIFY_REDIRECT_URL || ""
    );
    // Construct and return the URL
    const authRedirectUrl = `https://${hostDomain}/admin/oauth/authorize?client_id=${clientId}&grant_options[]&scope=${scope}&redirect_uri=${redirectUri}`;
    authRedirectUrl && window.location.replace(authRedirectUrl);
  };

 
  // useEffect(() => {
  //   setItem(
  //     "accessToken",
  //     "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMjljNDNiNS0yODlhLTExZWYtOWYyYS02ZjBjMmNlNzE1NTAiLCJpYXQiOjE3MTk5OTc5MDcsImV4cCI6MjMyNDc5NzkwN30.AyuOTbKEbUBYRtqxMlvnvzCOgWsNVWI_O_6DwHkmA4iWt6kjogqDlk6NUY4c-Go7PQfRpVC4PK8f3LDZObMngw"
  //   );
  //   navigate("/home");
  // }, []);
  const stripeAccountId = getItem("stripeAccountId");
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  useEffect(() => {
    setTimeout(() => {
      if (accessToken) {
        navigate("/home");
      }
    }, 1000);
  }, [accessToken]);
  useEffect(() => {
    if (!accessToken && fromStripe === "stripe" && stripeAccountId) {
      loginWithRedirect();
    }
  }, [ accessToken, fromStripe, stripeAccountId]);

  return (
    <div className={styles.loginWrapper}>
      <div>
        <div>
          <h3 className={styles.login}>Log in</h3>
          <p className={styles.welcome}>
            Welcome back! Please enter your myshopify URL to login
          </p>
        </div>
        <div>
          <div>
            <div className={styles.email}>
              <div className={styles.input}>
                <OutlinedInput
                  className={styles.domain}
                  value={userDomain}
                  onChange={handleInputChange}
                  endAdornment={
                    <InputAdornment position="end">
                      .myshopify.com
                    </InputAdornment>
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <Button
              variant="contained"
              color="success"
              className={styles.shopify}
              onClick={() => userDomain && constructAuthUrl(userDomain)}
            >
              Connect with Shopify
            </Button>
          </div>
          <div>
            <p>
              <span>Don't have an account? </span>
              <a href="https://apps.shopify.com/chargepay?utm_source=onboarding&utm_medium=app&utm_campaign=login_page">
                Register Now
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
