import { Link, useNavigate } from 'react-router-dom';
import styles from './style.module.scss';
import { svgIcons } from 'elements';
import Banner from '../../assets/banner.png';
import { useForm } from 'react-hook-form';
import auth0 from 'auth0-js';


type SignupFormType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const NewSignup = () => {
  const webAuth = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    redirectUri: process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URI as string,
  });
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SignupFormType>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  const password = watch('password');

  // our submit handler
  const onSubmit = (formData: SignupFormType) => {
    webAuth.signup(
      {
        connection: 'Username-Password-Authentication',
        email: formData.email,
        password: formData.password,
        userMetadata: {
          firstName: formData.firstName,
          lastName: formData.lastName,
        },
      },
      err => {
        console.log(err);
      }
    );
    navigate("/newLogin")
  };

  return (
    <div className={styles.signUpContainer}>
      <div className={styles.loginLeft}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div>
            <Link to='/home'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '20px 0',
                }}
              >
                <img
                  className={styles.logoIcon}
                  src={svgIcons.ChargePayLogo}
                  alt='tp'
                />
                <img
                  className={styles.logoName}
                  src={svgIcons.ChargePayName}
                  alt='ChargePay'
                />
              </div>
            </Link>
            <h2>Sign Up</h2>
          </div>
          <div style={{ display: 'flex', gap: '20px', margin: '10px 0' }}>
            <div>
              <label htmlFor='firstName'>First Name</label>
              <input
                {...register('firstName', {
                  required: 'First name is required',
                })}
                id='firstName'
                type='text'
                required
              />
              {errors.firstName && (
                <p style={{ color: 'red' }}>{errors.firstName.message}</p>
              )}
            </div>

            <div>
              <label htmlFor='lastName'>Last Name</label>
              <input
                {...register('lastName', { required: 'Last name is required' })}
                id='lastName'
                type='text'
                required
              />
              {errors.lastName && (
                <p style={{ color: 'red' }}>{errors.lastName.message}</p>
              )}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '10px 0',
            }}
          >
            <label htmlFor='email'>Email</label>
            <input
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Invalid email address',
                },
              })}
              id='email'
              type='email'
              required
            />
            {errors.email && (
              <p style={{ color: 'red' }}>{errors.email.message}</p>
            )}
          </div>

          <div style={{ display: 'flex', gap: '20px', margin: '10px 0' }}>
            <div>
              <label htmlFor='password'>Password</label>
              <input
                {...register('password', {
                  required: 'Password is required',
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters long',
                  },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).+$/,
                    message:
                      'Password must contain at least one uppercase letter, one number, and one special character',
                  },
                })}
                id='password'
                type='password'
                required
              />
              {errors.password && (
                <p style={{ color: 'red' }}>{errors.password.message}</p>
              )}
            </div>

            <div>
              <label htmlFor='confirmPassword'>Confirm Password</label>
              <input
                {...register('confirmPassword', {
                  required: 'Confirm password is required',
                  validate: value => {
                    if (value !== password) {
                      return 'Passwords do not match';
                    }
                  },
                })}
                id='confirmPassword'
                type='password'
                required
              />
              {errors.confirmPassword && (
                <p style={{ color: 'red' }}>{errors.confirmPassword.message}</p>
              )}
            </div>
          </div>

          <button type='submit' className={styles.signUpBtn}>
            Sign up
          </button>
        </form>

        <p>or sign up with</p>
        <div className={styles.authOptions}>
          <button className={styles.shopifyBtn}>
            <img src={svgIcons.ShopifyIcon} alt='' /> Shopify
          </button>
          <button className={styles.stripeBtn}>
            <img src={svgIcons.Stripe} alt='' /> Stripe
          </button>
        </div>
        <p>
          Already have an account?
          <Link
            to='/newLogin'
            // onClick={() =>
            //   loginWithRedirect()
            // }
            style={{ borderBottom: '1px solid #000' }}
          >
            Sign In
          </Link>
        </p>
      </div>
      <div className={styles.loginRight}>
        <img src={Banner} alt='Dashboard' className={styles.dashboardImage} />
        <h3>WIN CHARGEBACK ON AUTOPILOT</h3>
        <p>Reclaim More Revenue</p>
      </div>
    </div>
  );
};

export default NewSignup;
