import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Crisp } from 'crisp-sdk-web';
import Hotjar from '@hotjar/browser';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import Swal from 'sweetalert2';
import { Button, Menu, Badge } from '@mui/material';
import { Notification } from '../index';
import {
  getNotificationsListRequest,
  getShopsDataRequest,
} from '../../redux/actions';
import { clearItems, getItem } from '../../utils/localStorage';
import styles from './style.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { svgIcons } from 'elements';
import auth0 from 'auth0-js';

interface userDataProp {
  id: number;
  externalId: string;
  appId: string;
  domain: string;
  name: string;
  email: string;
  phone: string;
  shopType: string;
  bigCommerceStoreHash: string;
  bigCommerceStoreInternalHash: string;
  accessToken: string;
  refreshToken: string;
  scopes: string;
  onboarded: boolean;
  riskAccepted: boolean;
  chargeActive: boolean;
  shopOwner: string;
}

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<userDataProp>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { logout } = useAuth0();
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);

  const { pathname } = useLocation();
  const path = pathname.split('/')[1];

  const { data } = useSelector((state: any) => state?.shop?.addShopifyShop);

  const shopsData = useSelector((state: any) => state?.shop?.shopsData);
  const {
    data: stripeData,
    isLoading,
    isError,
  } = useSelector((state: any) => state?.stripe?.stripeLogin);

  const notifications = useSelector(
    (state: any) => state?.notifications?.notificationsList
  );

  const handleNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isHome = path === 'home';
  const isDisputes = path === 'dispute';
  const isSettings = path === 'settings';
  const isIntegrations = path === 'integrations';
  const fromStripe = getItem('fromStripe');
  // const handleLogout = () => {
  //   clearItems();
  //   if (fromStripe) {
  //     logout()
  //   }
  //   Swal.fire({
  //     icon: "success",
  //     title: "Logout successfully",
  //     text: "Thank you for using ChargePay",
  //   }).then((result) => {
  //     if (result.isConfirmed || result.isDismissed) {
  //       navigate("/login");
  //     }
  //   });
  // };

  const webAuth = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    redirectUri: process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URI as string,
  });

  const handleLogout = () => {
    // logout()
    Swal.fire({
      icon: 'success',
      title: 'Logout successfully',
      text: 'Thank you for using ChargePay',
    }).then(result => {
      if (result.isConfirmed || result.isDismissed) {
        clearItems()
        // webAuth.logout({
        //   returnTo: 'http://localhost:3000/newLogin',
        //   clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
        // });
        navigate('/newLogin');
      }
    });
  };

  useEffect(() => {
    if (shopsData?.isSuccess) {
      setUserData(shopsData?.data);
    }
  }, [shopsData]);

  useEffect(() => {
    if (userData?.id) {
      const crispWebsiteId = `${process.env.REACT_APP_CRISP_WEBSITE_ID}`;
      const dashboardBaseUrl = `${process.env.REACT_APP_DASHBOARD_BASE_URL}`;
      Crisp.configure(crispWebsiteId);
      Crisp.user.setEmail(userData?.email);
      Crisp.user.setNickname(userData?.name ?? 'user');
      Crisp.session.setData({
        shop_id: userData?.id,
        domain: userData?.domain ? userData?.domain : '',
        // total_disputes: 10,
        // total_disputes_awaiting_response: 3,
        dashboard_link:
          dashboardBaseUrl && `${dashboardBaseUrl}/store-view/${userData?.id}`,
      });

      //hotjar-integration
      const siteId = Number(`${process.env.REACT_APP_HOTJAR_BROWSER_SITE_ID}`);
      const hotjarVersion = 6;
      Hotjar.init(siteId, hotjarVersion);
      const userId = userData?.id?.toString();
      const FullName = userData?.name ? userData?.name : '';
      const domain = userData?.domain ? userData?.domain : '';
      Hotjar.identify(userId, {
        full_name: FullName,
        domain: domain,
      });
    }
  }, [userData]);

  useEffect(() => {
    dispatch(getShopsDataRequest());
    dispatch(getNotificationsListRequest());
  }, [dispatch]);

  return (
    <div className={styles.sidebarWrapper}>
      <div className={styles.sidebar}>
        <Link to='/home'>
          <div className={styles.logo}>
            <img
              className={styles.logoIcon}
              src={svgIcons.ChargePayLogo}
              alt='tp'
            />
            <img
              className={styles.logoName}
              src={svgIcons.ChargePayName}
              alt='ChargePay'
            />
          </div>
        </Link>
        <div className={styles.intro}>
          <div className={styles.heading}>
            <h3>
              Hi,{' '}
              {data?.name
                ? data?.name
                : userData?.shopOwner
                ? userData?.shopOwner
                : stripeData?.shopOwner
                ? stripeData?.shopOwner
                : ''}
            </h3>
            <p className={styles.selfService}>
              <span>Fully Managed Service </span>
              <img
                src={svgIcons.CircleCheck}
                alt='Fully Managed Service '
                className={styles.icon}
              />
            </p>
          </div>
          <div className={styles.headingIcons}>
            {notifications?.isSuccess &&
            notifications?.data?.status === 'New' &&
            notifications?.data?.count ? (
              <Badge
                badgeContent={
                  notifications?.isSuccess &&
                  notifications?.data?.status === 'New' &&
                  notifications?.data?.count
                }
                color='primary'
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <img
                  src={svgIcons.NotificationActive}
                  alt='notification'
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    handleNotification(event)
                  }
                />
              </Badge>
            ) : (
              <img
                src={svgIcons.NotificationInactive}
                alt='notification'
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleNotification(event)
                }
              />
            )}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  borderRadius: '11.808px',
                  background: '#FFF',
                },
              }}
            >
              <Notification handleClose={handleClose} />
            </Menu>
          </div>
        </div>
        <div className={styles.sidebarList}>
          <Link to='/home'>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.DashBoard} alt='home' />
              </div>
              <p className={cn({ [styles.active]: isHome })}>Dashboard</p>
            </div>
          </Link>
          <Link to='/dispute'>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Disputes} alt='Disputes' />
              </div>
              <p className={cn({ [styles.active]: isDisputes })}>Disputes</p>
            </div>
          </Link>

          <div className={styles.downSidebar}>
            <p>Account</p>
          </div>

          <Link to='/settings'>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Settings} alt='Settings' />
              </div>
              <p className={cn({ [styles.active]: isSettings })}>Settings</p>
            </div>
          </Link>
          <Link to='/integrations'>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Puzzle_Piece} alt='integrations' />
              </div>
              <p className={cn({ [styles.active]: isIntegrations })}>
                Integrations
              </p>
            </div>
          </Link>

          <div className={styles.listItem} onClick={handleLogout}>
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Logout} alt='Logout' />
              </div>
              <p className={styles.logout}>Logout</p>
            </div>
          </div>
          {/* <div className={styles.sidebarFooter}>
            <p>“Today’s work were yesterday’s impossible”</p>
            <h3>Ribert H. Schuller</h3>
          </div> */}
          <div
            className={cn(styles.listItem, styles.borderTop)}
            onClick={() => Crisp.chat.open()}
          >
            <div className={styles.list}>
              <div className={styles.icon}>
                <img src={svgIcons.Messages} alt='Live chat' />
              </div>
              <p>Chat with us ☎</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
