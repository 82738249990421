import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import { svgIcons } from 'elements';
import Banner from '../../assets/banner.png';
import { useForm } from 'react-hook-form';
import auth0 from 'auth0-js';
import { toast,ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from 'react';


type LoginFormType = {
  email: string;
  password: string;
};

const NewLogin = () => {
  const location = window?.location
  const webAuth = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    redirectUri: `${location?.origin}/home`,
  });
  const [isLoading,setIsLoading] = useState(false)


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormType>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (formData: LoginFormType) => {
    setIsLoading(true)
    try {
      webAuth.login(
        {
          realm: process.env.REACT_APP_AUTH0_REALM,
          username: formData.email,
          password: formData.password,
          responseType:"token"
        },
        (err, authResult) => {
          if (err) {
            setIsLoading(false)
            toast.error("invalid credentials",{position:"top-right"})
            console.error('Login failed', err);
            return;
          }
          setIsLoading(false)
          console.log('Login successful', authResult);
        }
      );
    } catch (err) {
      console.error('Error during login:', err);
    }
  };
  return (
    <div className={styles.loginContainer}>
      <ToastContainer />
      <div className={styles.loginLeft}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Link to='/newLogin'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '20px 0',
              }}
            >
              <img
                className={styles.logoIcon}
                src={svgIcons.ChargePayLogo}
                alt='tp'
              />
              <img
                className={styles.logoName}
                src={svgIcons.ChargePayName}
                alt='ChargePay'
              />
            </div>
          </Link>
          <h2>Sign In</h2>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '10px 0',
            }}
          >
            <label htmlFor='email'>Email</label>
            <input
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Invalid email address',
                },
              })}
              id='email'
              type='email'
              required
            />
            {errors.email && (
              <p style={{ color: 'red' }}>{errors.email.message}</p>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '10px 0',
            }}
          >
            <label htmlFor='password'>Password</label>
            <input
              {...register('password', {
                required: 'password is required',
              })}
              id='password'
              type='password'
              required
            />
            {errors.password && (
              <p style={{ color: 'red' }}>{errors.password.message}</p>
            )}
          </div>

          <div className={styles.options}>
            <label>
              <input type='checkbox' /> Remember Me
            </label>
            <Link to='#'>Forgot Password?</Link>
          </div>
          <button type='submit' className={styles.signInBtn}>
            {isLoading ? "loading..." : "Sign In"}
          </button>
        </form>
        <p>or sign in with</p>
        <div className={styles.authOptions}>
          <button className={styles.shopifyBtn}>
            <img src={svgIcons.ShopifyIcon} alt='' /> Shopify
          </button>
          <button className={styles.stripeBtn}>
            <img src={svgIcons.Stripe} alt='' /> Stripe
          </button>
        </div>
        <p>
          Don’t have an account?
          <Link
            to='/newSignup'
            style={{ borderBottom: '1px solid #000' }}
          >
            Sign Up
          </Link>
        </p>
      </div>
      <div className={styles.loginRight}>
        <img src={Banner} alt='Dashboard' className={styles.dashboardImage} />
        <h3>WIN CHARGEBACK ON AUTOPILOT</h3>
        <p>Reclaim More Revenue</p>
      </div>
    </div>
  );
};

export default NewLogin;
